.col-xs-1 { @extend .col-1 }
.col-xs-2 { @extend .col-2 }
.col-xs-3 { @extend .col-3 }
.col-xs-4 { @extend .col-4 }
.col-xs-5 { @extend .col-5 }
.col-xs-6 { @extend .col-6 }
.col-xs-7 { @extend .col-7 }
.col-xs-8 { @extend .col-8 }
.col-xs-9 { @extend .col-9 }
.col-xs-10 { @extend .col-10 }
.col-xs-11 { @extend .col-11 }
.col-xs-12 { @extend .col-12 }

.rating-star-yellow > label, .rating-star-green > label, .rating-star-blue > label {
  margin: 0;
}
.product-list {
  #gsr-review-list {
    .gsr-review-count-rating {
      display: none;
    }
  }
  .gsr-clr_20 {
    display: none !important;
  }
  .grid .product-miniature #gsr-review-list {
    .gsr-text-center {
      text-align: left !important;
    }
  }
  .list .product-miniature #gsr-review-list {
    .gsr-text-center {
      text-align: right !important;
    }
  }
} 
.aone-homepage .product-list .list .product-miniature #gsr-review-list {
  position: static;
  margin-top: -7px;
}
.main-product-details #gsr {
  &.productAction, &.productReassurance {
    margin-bottom: 20px;
    margin-top: 20px;
    background: $color_gray;
    overflow: hidden;
    .block-review {
      background: transparent;
    }
    .block-review-item {
      padding: 0;
      .display-distribution {
        background: $light-background;
      }
    }
  }
  &.productAction {
    padding: 0px 20px 10px;
  }
  &.productReassurance {
    padding: 10px 20px 20px;
  }
}

#gsr {
  #comment-form {
    .product {
      margin-bottom: 20px;
      overflow: hidden;
      img {
        max-width: 90px;
      }
    }
    #footer {
      background: transparent;
    }
  }
  .pull-right .btn.btn-default {
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .review-line-comment {
    .row > div {
      padding: 0 15px;
    }
  }
}
#footer #gsr {
  width: 100%;
}
