$webapic_pink1: #d83381;
$webapic_pink2: #ff57a5; 
$webapic_white: #fcfdff;
$webapic_green: #a4ec37;
$webapic_blue: #440099;

/*********GLOBAL*********/
h1,h2,h3,h4,h5,h6{
	margin: 0;
}
p{
	margin: 0;
}

.maxwidth{
	max-width: 1200px;
	margin: 0 auto;
}
a.link-underline{
	&::after{
		content: '';
		width: 100%;
		height: 2px;
		position: absolute;
		left: 0;
		bottom: 0;
		background-color:$webapic_pink1;
	}
	position: relative;
	padding-bottom: 1rem;
	color: $webapic_pink1;
	font-size: 16px;
	line-height: 1.75;
	letter-spacing: 0.8px;			
}
.title-block{	
	padding: 0;
	font-size: 20px;
	font-weight: 600;
	line-height: 1.3;
	color: #000;
	text-transform: none;
	strong{
		color: $webapic_pink1;
	}
}
.full-width {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}
/*********SURCHARGE BOOTSTRAP*********/
.btn-primary{
	color: #000;
}


/***********************************************/
body{
	background-color: #fff;
	position: relative;

}

/*********HEADER*********/
header{
	background-color: $webapic_pink1;
	.sld-banner{
		font-size: 13px;
		font-weight: 300;
		line-height: 2.15;
		letter-spacing: -0.33px;
		color: #ffffff;
		border-bottom: 1px solid $webapic_pink2;
		.sld-banner-ctn{
			display: flex;
			align-items: center;
			.sld-banner-left{
				width: 50%;
			}
			.sld-banner-right{
				width: 50%;
				font-size: 11px;
				line-height: normal;
				letter-spacing: 0.55px;
				.header-phone{
					display: flex;
					justify-content: space-between;
					align-items: center;
					
					.left{
						width: calc(100% / 3 * 2);
						text-align: center;
						span{
							padding-left: 1rem;
							background: transparent url('../img/icon-hermine.svg') no-repeat 0% 0%;
							border-bottom: 2px solid #fff;
							padding-bottom: 0.85rem;
							position: relative;
							&::before{
								position: absolute;
								width: 33%;
								height: 2px;
								content: '';
								z-index: 9;
								left: 0;
								bottom: -2px;
								background: #1115b8;
							}
							&::after{
								position: absolute;
								width: 33%;
								height: 2px;
								content: '';
								z-index: 9;
								right: 0;
								bottom: -2px;
								background: #c10007;
							}
						}						
					}
					.right{
						width: calc(100% / 3);
						background-color: $webapic_pink2;
						text-align: center;
						line-height: 19px;
						letter-spacing: 0.83px;
						padding: 0.2rem 2rem;
						a{
							display: block;
							font-size: 16px;
							color: #fff;
							font-weight: 400;
						}
					}
					
				}
			}
		}
		
	}
	.main-header{
		padding: 0;
		align-items: center;
		.header-logo{
			width: 25%;
			max-width: initial !important;
			a{				
				&:hover img{
					transform: scale(1.1);
				}
			}
			.logo{
				transition: all 0.5s ease;
				max-height: 119px !important;
				margin-top: -0.25rem;
			}
		}
		.header-right{
			width: 75%;
			display: flex;
			.sticky-search,
			.logo-mobile{
				display: none;
			}
			.searchbar-wrapper{
				width: 80%;
				padding: 0 5rem;			
				.search-widget{
					width: 100%;
					input{
						border: none;
						color: rgba(51, 51, 51, 0.6);
						font-size: 16px;
						line-height: 28px;
						letter-spacing: 0.4px;
						height: 50px;						
					}
					button{
						width: 62px;
    					height: 50px;
					}
				}
			}
			.customer-signin-module,
			.shopping-cart-module{
				width: 10%;
				.user-info .account-link{
					border: none; 
					a{
						white-space: normal;
						color:#fff;
					}
				}
			}
		}
		
	}
	.header-bottom{
		background: #333333;
		margin-top: -0.4rem;
		.anav-top {
			justify-content: space-between;
			.amenu-item{
				&:hover{
					background: none !important;
					.amenu-link{
						color: $webapic_pink1;
						span::after{
							left: auto !important;  
						} 
					}
				}
				&.promo{
					background-color: $webapic_green;
					a{
						color: #000;
						font-size: 16px;
						line-height: 1.75;
						letter-spacing: 0.4px;
						text-transform: uppercase;
						// padding: 15px 30px;
						padding: 15px 20px;
						font-weight: bold;
					}
				}
				.amenu-link{
					color: $webapic_white;
					font-weight: 400;
					font-size: 16px;
					line-height: 1.75;
					letter-spacing: 0.4px;
					text-transform: none;
					span::after{
						right: 0;
						top: calc(50% - 3px);
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 6px 5px 0 5px;
						border-color: $webapic_green transparent transparent transparent;
					}
				}
				.adropdown{
					width: 100vw !important;
					z-index: 99999999999 !important;
					.category-title a{
						text-transform: none;
						background: transparent url('/img/webapic/picto-li.svg') no-repeat 0 55%;
						padding-left: 1rem;
					}
					.acategory-content .aitem{
						padding-bottom: 1.25rem; 
					}
				}
			}
			.acategory-content .category-subs a{
				background: transparent url('/img/webapic/picto-li.svg') no-repeat 0 50%;
				padding-left: 1rem;
				&::before{
					display: none;
				}
			}
		}
	}
}

/*********FOOTER*********/
#index footer{
	margin-top: 0;
}
footer{
	margin-top: 10rem;
	.footer-top{
		@extend .maxwidth;
		background-color: $webapic_pink1;
		padding: 2rem 10%;
		margin: -58px auto;
		z-index: 9;
		position: relative;
		.block-newsletter{
			display: flex;
			align-items: center;
			justify-content: center; 
			margin: 0;
			> p{
				width: 45%;
				font-size: 14px;
				font-weight: 300;
				line-height: 1.86;
				letter-spacing: 0.35px;
				padding-right: 2rem;
				color: #fff; 
				margin: 0;
				strong{
					display: block;
					font-size: 18px;
					line-height: 26px;
					letter-spacing: 0.9px;
				}
			}
			form{
				width: 55%;
				position: relative;
				&::before{
					content: "\f0e0";
					transform: rotate( 90deg);
					color: $webapic_pink1;
					font-size: 1.5rem;
					position: absolute;
					left: 1rem;
					top: 1rem;
				}
				input{					
					&::placeholder{
						font-size: 16px;
						font-weight: 300;
						line-height: 1.19;
						color: rgba(51, 51, 51, 0.5);
					}
					padding: 0 0 0 2.5rem;
					border-radius: 0;
					height: 52px;
					font-size: 16px;
					font-weight: 300;
					line-height: 1.19;
					color: rgba(51, 51, 51, 0.5);
					
				}
				.input-wrapper{
					display: flex;
					align-items: center;
					span{
						font-size: 20px;
						line-height: 0.95;
						letter-spacing: 0.5px;
						color: #000;
						button{
							border: none;
							height: 52px;
							font-size: 20px;
							line-height: 0.95;
							letter-spacing: 0.5px;
							padding: 0 1.5rem;
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
	.footer-main{
		background:  #333 url('/img/webapic/fond-footer.png') no-repeat 0% 0%;	
		padding-top: 10rem;
		font-size: 14px;
		font-weight: normal;
		line-height: 2;
		letter-spacing: 0.7px;
		color: #fff;
		.h4{
			font-size: 18px;
			font-weight: 600;
			line-height: 1.39;
			letter-spacing: 0.45px;
			text-transform: none;
			color: #fff;
			margin-bottom: 2rem;
		}
		ul{
			&.reseaux a{
				&::before{
					display: none;
				}
				img{
					width: 60px;
					filter: invert(99%) sepia(98%) saturate(120%) hue-rotate(45deg) brightness(114%) contrast(100%);
				}
			}
			a{
				position: relative;
				padding-left: 0.75rem;
				&::before{
					left: 0;
					position: absolute;
				}
			}
		}
		.footer-bottom{
			text-align: center;
			margin-top: 2.75rem;
			.typo{
				display: flex;
				flex-direction: column;
				div{
					font-size: 11px;
					font-weight: 300;
					letter-spacing: 0.55px;
					color: #fff;
					span.drapeau{
						border-bottom: 2px solid #fff;
						position: relative;
						display: inline-block;
						margin-bottom: 0.75rem;
						&::before{
							position: absolute;
							width: 33%;
							height: 2px;
							content: '';
							z-index: 9;
							left: 0;
							bottom: -2px;
							background: #1115b8;
						}
						&::after{
							position: absolute;
							width: 33%;
							height: 2px;
							content: '';
							z-index: 9;
							right: 0;
							bottom: -2px;
							background: #c10007;
						}
					}		
				}
				span{
					display: block;
					font-size: 10px;
					font-weight: 300;
					line-height: 3.5;
					letter-spacing: 0.25px;
					color: rgba(255, 255, 255, 0.5);
				}
			}
			
		}
		
	}
}

/*********HOME*********/

#wrapper{
	.breadcrumb-wrapper{
		margin: 0;
	}
	h1{
		font-size: 25px;
		font-weight: 600;
		color: #000;
		text-align: center;
		margin: 80px 0;
		span{
			color: $webapic_pink1;
		}
	}
	.aone-slideshow {
		margin: 0;
		position: relative;
		min-height: 430px;
		// &::after{
		// 	background-image: linear-gradient(to top, rgba(237, 0, 112, 0.75), rgba(255, 69, 157, 0.95));
		// 	position: absolute;
		// 	width: 100%;
		// 	height: 100%;
		// 	content: '';
		// 	z-index: 9;
		// 	left: 0;
		// 	top: 0;
		// }
		.nivo-html-caption,
		.nivo-caption{
			@extend .maxwidth;
			padding: 5% 0 0 10%;  
			z-index: 99;
			.caption-wrapper{
				justify-content: flex-start; 
				align-items: flex-start;
				.caption-content{
					padding: 0;
					div{
						font-size: 60px;
						font-weight: 600;							
						letter-spacing: 1.5px;
						text-transform: uppercase;
						span{							
							background-color: #ffffff;							
							color: #000;
							padding: 5px 30px;	
							line-height: 60px;						
						}
						strong{
							background-color: $webapic_green;
							letter-spacing: -1.5px;
							color: #fcfdff;
							padding: 5px 30px;
							line-height: 60px;
						}
					}
				}
			}
			.slide-products-related{
				width: 100%;
				top: 0;
				right: 0;
				transform: none;
				.slide-products-related-list{
					background: none;
					opacity: 1;
					article{
						.product-container{
							display: flex;
							align-items: center;
							padding-left: 20%;
							> div{
								width: 50%;	
								position: relative;
								&.left{
									padding-top: 180px;
									.product-name{
										a{
											line-height: 1.9rem;
											font-size: 32px;
											color: #fff;
											font-weight: 300;	
											margin-bottom: 1.5rem;
										  	display: inline-block;								
										}
									}
									span{
										font-size: 60px;
										font-weight: 600;
										color: #fff;
									}
									a.link{
										border: solid 2px #fcfdff;
										color: #ffffff;
										font-size: 18px;
										letter-spacing: 0.9px;
										padding: 25px 35px;
										margin-top: 1.5rem;
										display: inline-block;
										transition: all 0.5s ease;
										&:hover{
											transform: scale(1.1);
											border: solid 2px #a4ec37;
										}
									}
								}	
								&.right{
									padding-top: 30px;
									img{
										width: auto;
										height: 100%;
										max-height: 375px;
										&.marque{
											position: absolute;
											right: 0;
											top: inherit;
											left: inherit;
											bottom: 0;
											max-width: 250px;
											height: auto;
										}
									}
								}							
								
							}
						}
					}
				}
				
			}
			
		}
		.nivo-controlNav{
			@extend .maxwidth;
			display: flex;
			justify-content: space-around;
			width: calc(100% / 12  * 8);
			z-index: 999;
			left: 0;
			bottom: 20px;
			position: relative;
			padding: 0 10px;
			a{
				&.active{
					opacity: 1;
				}
				width: 33%;
				height: 6px;
				background-color: #fff;
				border: none;
				border-radius: inherit;
				opacity: 0.3;
			}
		}
		.a-banner img {
			height: 100%;
			width: auto;
		}
	}
	.aone-homepage.home-blocks-top{
		position: relative;
		&::before{
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			opacity: 0.2;
			background:#c2c2c2 url('/img/webapic/bg-legumes.svg') no-repeat 50% 0%;
		}
	}
	.home-top{
		@extend .maxwidth;
		background-color: #fff;
		display: flex;
		align-items: center;
		padding: 40px 10% 40px 10%;
		position: relative;
		margin-top: -35px;
		z-index: 99;
		&::after{
			content: '';
			position: absolute;
			width: 50vw;
			height: 100%;
			right: -50vw;
			opacity: 1;
			top: 0;
			background: #fff;
		}
		h2{
			width: 25%;
			font-size: 20px;
			font-weight: 600;
			color: #000;
			strong{
				color: $webapic_pink1;
			}
		}
		img{
			max-width: 15%;
			width: 100%;
			height: auto;
			padding: 0 1rem;
		}
	}
	.home-producttabs{
		margin-top: 2rem;
		padding-bottom: 4rem;
		h2{
			font-size: 50px;
			font-weight: 600;
			color: #000;
			text-align: center;
			margin-bottom: 4rem;
			strong{
				color: $webapic_pink1;
			}
		}
		.nav-tabs{
			margin-bottom: 2rem;
			.nav-item{
				.nav-link{
					&.active{
						border-bottom: 5px solid $webapic_green;
						opacity: 1;
					}
					padding: 0 2rem 0.75rem 2rem;
					font-size: 25px;
					font-weight: 400;
					line-height: 1.44;
					color: #333333;
					text-transform: none;
					border-bottom: 5px solid rgba(51, 51, 51, 0.4);	
					opacity: 0.4;
					
				}
			} 
		} 
		.hometab-link{
			text-align: center;
			margin-top: 4rem;
		
		}
	}
	.aone-brands{
		padding-top: 3rem;
		border-top: 1px solid rgba(51, 51, 51, 0.4);	;
		.title-block{
			margin: 0 0 2rem 0;
			text-align: center;
		}
		a{
			max-height: 80px;
			img{
				box-shadow: none;
				width: auto;
				max-height: 80px;
			}
		}
	}
	.home-cdp-ref{
		&::before{
			background: #333 url('/img/webapic/fond-cdp-ref.svg') no-repeat 0% 0%;
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			opacity: 0.15;
		}
		&::after{
			background: transparent url('/img/webapic/img-cdp-ref.jpg') no-repeat 50% 0%;
			background-size: cover;
			content: '';
			position: absolute;
			width: 30%;
			height: 100%;
			right: 0;
			top: 0;
		}
		position: relative;		
		// background: transparent url('/img/webapic/img-cdp-ref.jpg') no-repeat 0% 0%;		
		.home-cdp-ref-ctn{
			@extend .maxwidth;			
			z-index: 9;
			padding: 130px 10%;
			position: relative;
			h2,
			p{
				max-width: 55%;
			}
			h2{
				font-size: 25px;
				font-weight: 600;
				font-stretch: normal;
				line-height: 30px;
				margin-bottom: 3rem;
				span,
				strong{
					color: $webapic_pink1;
				}
			}
			p{
				opacity: 0.8;
				font-size: 16px;
				font-weight: 300;
				line-height: 30px;
				letter-spacing: 0.4px;
				color: rgba(51, 51, 51, 0.8);
				margin-bottom: 3rem;
			}
			.partenaire{
				background: transparent url('/img/webapic/fond-cdp-partenaire.png') no-repeat -10px 0%;
				left: 60%;
				position: absolute;
				top: 50px;
				width: 296px;
				height: 316px;
				display: flex;
				flex-direction: column;
				text-align: center;
   				 padding: 50px;
				justify-content: space-between;
				span{
					&::before{
						width: 20px;
						height: 1px;
						background-color: $webapic_pink1;
						position: absolute;
						content: '';
						bottom: 0;
    					left: calc(50% - 10px);
					}
					text-transform: uppercase;
					position: relative;
					font-size: 13px;
					font-weight: 300;
					line-height: 2.65;
					letter-spacing: 0.65px;
					color: $webapic_pink1;
				}
				span + span{
					font-size: 20px;
					font-weight: 300;
					color: #040404;
					line-height: 1.4;
					text-transform: none;
					&::before{
						display: none;
					}
				}
				img{
					width: 100%;
					height: auto;
				}
				span.annee{
					color: #4c4c4c;
					font-size: 24px;
					position: absolute;
					right: 4rem;
					bottom: 2rem;

				}
			}
		}
	}
	.block_id_12{
		display: flex;
		position: relative;
		padding: 4rem 0;
		margin-bottom: 6rem;
		&::before{	 		
		    content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			width: 150vw;
			left: -50vw;
 		}
		.product-list{
			z-index: 99;
			width: 67%;
			padding-right: 2rem;
			.product-list-wrapper{
				position: relative;
				.slick-prev{
					left: -16rem;
					// right: calc(67% + 9rem);
					bottom: 2rem;
    				top: auto;
				}
				.slick-next{
					left: -11.5rem;;
					// right: calc(67% + 4.55rem);
					bottom: 2rem;
   					 top: auto;
				}
			}
		}
		.ventes-flash{
			z-index: 99;
			background: transparent url('/img/webapic/ventes-flash.svg') no-repeat calc(100% - 5rem) 0; 
			width: 67%;
			text-align: right;
			padding-top: 6rem;
			padding-right: 5rem;
			h3{
				font-size: 50px;
				line-height: 55px;
				color: #333;
				strong{
					color: $webapic_pink1;
				}
			}
			.view-all-link{
				display: block;
				margin: 2rem 0;
				a{
					font-size: 16px;
					line-height: 28px;
					color: $webapic_pink1;
					padding-bottom: 0.5rem;
					position: relative;
					
					margin: 2rem 0;
					&::after{
						background-color: $webapic_pink1;
						width: 100%;
						height: 2px;
						position: absolute; 
						bottom: 0;
						left: 0;
						content: '';
					}
				}
			}
			
		}
	}
	.aone-homepage.home-blocks-middle{
		padding-bottom: 50px;
		.block_id_12{
			background: #fff;
			&::before {
				content: "";
				display: block;
				background-color: #fff;
				width: 100vw;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}

}

#index{
	background: transparent url('/img/webapic/bg-legumes.svg') no-repeat 50% 0%;
	background-attachment: fixed;
	position: relative;
	&::before{
		background-color: #c2c2c2;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0; 
		position: absolute; 
		content: '';
		opacity: 0.2;
	}
}
#index .outside-container.bottom-column-content{
	background-color: #fff;
	padding-top: 1rem;
}
/*********BLOCK REASSURANCE*********/
.block-reassurance{
	position: relative;
	background-color: #333333;
	padding-top: 50px;
	margin-bottom: 135px;
	&::after,
	&::before{
		content: '';
		width: 9600px;
		height: 100%;
		position: absolute;
		right: 100%;
		top: 0;
		background-color: #333333;
	}
	&::after{ 
		left: 100%;
		background-color: #333333;
	}
	ul{
		max-width: 1000px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;  
		// &::after{
		// 	position: absolute;
		// 	content: '';
		// 	left: 10%;
		// 	box-shadow: 0px 0px 57px 0px rgba(1, 2, 1, 0.55);
		// 	width: 80%;
		// 	height: 50px;
		// 	height: 100px;
		// 	bottom: -80px;
		// }
		li{
			&:first-of-type{
				width: 100%;
				font-size: 25px;
				color: #fff;
				background: none;
				z-index: 99;
				margin: 0;
				strong{
					color: $webapic_green;
					font-size: 25px;
					font-weight: 400;
					display: inherit;
					margin-left: 0.35rem;
				}
				span{
					display: flex;
					div{
						flex-direction: row;
						margin-right: 6rem;
					}
					div + div{
						margin: 0 4rem 0 4rem;
					}
				}
				img{
					position: absolute;
    				top: 75px;
				}
			}
			&.li2,
			&.li4{
				background-color: $webapic_green;
				color: #000000;
			}
			width: 25%;
			padding: 40px;
			text-align: center;
			background-color: $webapic_pink1;
			color: #fff;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.6px;
			z-index: 9;
			margin-bottom: -80px;
			strong{
				display: block;
				font-size: 16px;
				letter-spacing: 0.4px;
			}
			div{
				display: flex;
				flex-direction: column;
				padding: 0;
				span{
					margin-top: 1rem;
				}
			}
		}
	}
}

/*********PRODUIT LISTING*********/
div.product-list .grid{
	@extend .maxwidth;
	article{
		padding: 0;
		margin: 0 0 3rem;
		.product-container{

			box-shadow: none;
			background: none;
			padding: 0 1rem;
			.first-block{
				a div{
					display: none;
				}
				a:hover{
					position: relative;
					div{
						position: absolute;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 100%;
						width: 100%;
						content: '';
						span{
							border-radius: 43px;
							background-color: rgba(68, 0, 153, 0.95);
							color: #fff; 
							padding: 0.9rem 0.5rem;
							font-size: 3rem;
						}
					}
				}
			}
			.second-third-block{
				position: relative;
				padding-top: 1.5rem;				
				margin-top: 3rem;
				&::before{
					position: absolute;
					opacity: 0.2;
					left: 0;
					top: 0;
					height: 1px;
					width: 100%;
					background-color: #333;
					content: "";
				}
				h5{
					padding: 0;				
					a{
						color: rgba(51, 51, 51, 0.7);
						font-size: 12px;
						line-height: 1.58;
						letter-spacing: 0.3px;
						font-weight: 300;
						text-align: left;
					}
				}
				.third-block{
					margin: 0 0 0 0;
					.third-block-left{ 
						margin: 0;
						width: 100%;						
					}
					// .buttons-sections{
					// 	height: 70px;
					// 	color: #000000;
					// 	line-height: 28px;
					// 	font-size: 18px;
					// 	letter-spacing: 0.45px;
					// 	text-transform: none;
					// 	box-shadow: 0px 5px 13px 0 rgb(51 51 51 / 24%); 
					// 	background: linear-gradient(90deg, #a4ec37 0%, #bcff57 100%);
					// }
					.first-prices{
						.discount{
							display: none;
						}
					}
		
				}
				.marque{
					position: absolute;
					right: 0;
					top: -10px;
					img{
						width: auto;
    					height: 40px;
					}
				}
				.promo2{
					position: absolute;
					left: 0;
					top: -10px;
					background: #d83381;
					span{
						padding: 0 5px;
						font-size: 1rem;
						text-transform: uppercase;
						color: #fff;
					}
				}
			}
			.buttons-sections{
				justify-content: center;
				.add-to-cart {
					margin-top: 1rem;
					color: #000000;
					line-height: 28px;
					font-size: 18px;
					letter-spacing: 0.45px;
					text-transform: none;
					box-shadow: 0px 5px 13px 0 rgba(51, 51, 51, 0.24);
					background: linear-gradient(90deg, rgba(164,236,55,1) 0%, rgba(188,255,87,1) 100%);
					&:hover{
						background: linear-gradient(90deg,  rgba(188,255,87,1) 0%, rgba(164,236,55,1) 100%);
					}
				}
			}
			.ventesflash-compteur{
				.countdown-box{
					padding: 4.25rem 0 0 0;
				}
				time{
					font-size: 16px;
				}
			}
			.delivery{
				color: $webapic_pink1;
				font-size: 12px;
				line-height: 2;
				letter-spacing: 0.25px;
				margin-top: 1rem;
				background: transparent url('/img/webapic/picto-check.svg') no-repeat 0% 50%;
				padding-left: 1.75rem;
				& + .delivery{
					margin: 5px 0 0 0;
				}
			}
		}
	}
}
.slick-slider .slick-arrow{
	background-color: rgba(51, 51, 51, 0.4);	
	width: 60px;
  	height: 60px;
	opacity: 0.2;
	top: 35%;	
	&::before{
		font-size: 4rem;
		color: $webapic_blue;
		line-height: 55px;
	}
	&.slick-prev{
		left: -60px;		
	}
	&.slick-next{
		right: -60px;
	}
}
div.product-price-and-shipping{
	width: 100%;
	margin: 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	> div{
		&.first-prices{
			width: 40%;
			display: flex;
			flex-direction: column;
			.discount{
				color: $webapic_pink1;
				font-size: 22px;
				margin-bottom: 0.5rem;
				font-weight: bold;
				border: 2px solid $webapic_pink1;
				text-align: center;
			}
		}
		&.second-prices{
			width: 60%;
			text-align: right;
			display: flex;
			flex-direction: column;
		}
		span{
			margin: 0;
		}
		.price-ttc{
			font-size: 13px;
			font-weight: 300;
			line-height: 1.79;
			color: rgba(51, 51, 51, 0.7);
			margin: 0 !important;
		}
		.price-ht{
			font-size: 22px;
			font-weight: 600;
			line-height: 1.04;
			color: $webapic_blue;
			margin: 0 !important;
			span{
				font-size: 13px;
				margin: 0 !important;
			}
		}
		.price-reduction{
			margin: 0 0 0.5rem 0 !important;
			font-size: 14px;
			font-weight: 300;
			line-height: 1.79;
			text-decoration: line-through;
			color: $webapic_blue;
			position: relative;		
			&.noreduction{
				text-decoration: none;
			}
		}
	}
}
div.product-flags{
	.discount{
		background: transparent url('/img/webapic/fond-promo.png') no-repeat 0% 0%;
		width: 93px;
		height: 86px;
		font-size: 18px;
		font-weight: 600;
		color: #fff;
		letter-spacing: -1.2px;
		line-height: 47px;
		padding-left: 0.75rem;
		transform: scale(1.2);
	}
}
.miniature-countdown-wrapper{
	left: auto !important;
	right: 0;
	width: 59px;
	height: 100%;
	min-width: auto !important; 
}
.ventesflash-icone{
	position: absolute;
	color: #fff;
	text-transform: uppercase;
	transform: rotate(-5deg);
	left: -5px;
    top: 8px;
	span{
		font-size: 12px;
		margin-left: 18px;
	}
	span + span{
		font-size: 25px;
		margin: 0;
	}
}
.ventesflash-compteur{
	background: rgba(68, 0, 153, 0.95);
	
	height: 100%;
	&::before{
		width: 102px;
		position: absolute;
		left: -1.5rem;
		top: 0rem;
		content: '';
		background: transparent url(/img/webapic/chrono.png) no-repeat 0% 0%;
		height: 74px;
	}
	.countdown-box{
		flex-direction: column;
		height: 100%;
		padding: 6rem 0 1rem 0;
		i{
			display: none;
		}
		span{
			text-transform: uppercase;
			font-size: 8px;							
			color: #fff;
			 word-wrap: break-word;
			 overflow: hidden;
			 white-space: normal;
			time{
				white-space: normal;
				font-size: 24px;
				word-wrap: break-word;
				overflow: hidden;
				strong{
					font-size: 8px;
			
				}
			}
			&.countdown-time{
				display: flex;
				flex-direction: column;								
				height: 100%;
				width: 100%;
				text-align: center;
			}
		}
	}
}

/*********PRODUIT PAGE*********/
#product{
	overflow-x: hidden;
	.main-content .container{
		max-width: none;
		padding: 0;
	}
	.wp-product{
		display: flex;
		justify-content: center;
		position: relative;
		padding-top: 1rem;
		// @extend .maxwidth;	
		.main-product-bottom-bg{
			&::before{
				// background: #333 url('/img/webapic/fond-legumes2.svg') no-repeat 5% 0%;
				background: transparent url('/img/webapic/fond-legumes2.svg') no-repeat 5% 0%;
				content: '';
				position: absolute;
				width: 200vw;
				height: 100%;
				opacity: 0.12;
				@extend .full-width;
			}
			
		}		
		.wp-product-left{
			width: 50%;
			max-width: 510px;
			position: relative;
			.marque{
				top: -0.25rem;
				position: absolute;
				z-index: 9;
				left: 38%;
				max-width: 120px;
			}
			.product-left{
				background: #fff;
				position: relative;
				padding: 40px 0;
				// .product-left-bg{
				// 	&::before{
				// 		background:#333;
				// 		content: '';
				// 		position: absolute;
				// 		width: 200vw;
				// 		height: calc(100% + 20px);
				// 		opacity: 0.05;
				// 		@extend .full-width;
				// 	}
					
				// }
				
				.product-left-content{			
					z-index: 99;
					position: initial;
					padding: 0 2rem;
					// &::before{
					// 	content: "";
					// 	background-color: #fff;
					// 	width: 100vw;
					// 	height: 100%;
					// 	position: absolute;
					// 	top: 0;
					// 	right: 0;
					// }
					// &::after{
					// 	bottom: -2rem;
					// 	background:#fff;
					// 	content: '';
					// 	position: absolute;
					// 	width: 100vw;
					// 	height: 2rem;
					// 	right: 0;
					// }
					.images-container{
						padding-top: 50px;
						.product-cover{
							img{
								// cursor: zoom-in;	
								width: 100%;
								height: auto;
								transition: .5s ease;
								backface-visibility: hidden;							
							}
							.middle {
								cursor: pointer;
								transition: .5s ease;
								opacity: 0;
								position: absolute;
								top: calc(50% - 8rem);
								left: 50%;
								transform: translate(-50%, -50%);
								-ms-transform: translate(-50%, -50%);
								text-align: center;
							}							  
							&:hover img {
								opacity: 0.8;
							}							  
							&:hover .middle {
								opacity: 1;
							}							  
							.text {
								background-color: #409;
								color: #fff;
								font-size: 45px;
								padding: 10px;
								opacity: 0.75;
							}
							
						} 
					}
					
				}
			}
			.main-product-bottom{		
				position: relative;
				
				
				.product-normal-layout{
					position: relative;
					z-index: 9;
					padding: 3rem 0 7rem 0;
					.product-description{
						ul{
							padding: 0;
							margin: 2rem 0;
							list-style: none;
							li{
								background: #f5f5f5 url('/img/webapic/picto-li.svg') no-repeat 2rem 55%;
								font-size: 14px;
								font-weight: 300;
								line-height: 3;
								letter-spacing: 0.35px;
								color: #000;
								margin-bottom: 2px;
								padding: 0 0 0 3rem;
								strong{
									color: $webapic_pink1;
								}
							}
						}
						span strong{
							color:rgba(216, 51, 129, 0.8) !important;
							font-size: 16px;
							line-height: 30px;
						}
						p{
							font-size: 16px;
						}
					}
				}
			}
			.product-flags{
				position: absolute;
				background:  #d83381;
				left: 0;
				top: -0.25rem;
				display: block !important;
				text-align: center;
				padding: 0.25rem;
				// display: none;
				.product-flag {
					padding-left: 0;
					font-size: 1.75rem;
					text-transform: uppercase;
					color: #fff;
					background: none;
				}
			}
			.wk_show_label_product{
				display: flex;
				justify-content: center;					   
				bottom: 10px;
				position: absolute;
				width: 100%;
				border: 1px solid #d83381;
				padding: 0.75rem 0;
				> div{
					position: initial !important;
					left: inherit !important;
					right: inherit !important;
					margin: 0 0.25rem !important;
					img{
						width: 75px !important;
						height: 75px !important;
					}
				}
			}
		}
		.wp-product-right{
			width: 50%;
			max-width: 490px;
			z-index: 99;
			padding-top: 50px;
			.product-right{
				padding-left: 110px;
				position: sticky;
				top: 80px;
				padding-bottom: 2rem;
				&.is-pinned{
					.js-product-countdown,
					.product-description-short,
					.marque{
						display: none !important;
					}
					.wp-service{
						display: block;
					}
				}
				h1{
					color: $webapic_blue;
					font-size: 33px;
					font-weight: 600;
					line-height: 1.3;
					text-align: left;
					margin: 0;
					position: relative;
					margin-bottom: 2rem;
					padding-bottom: 2rem;
					&::after{
						position: absolute;
						opacity: 0.2;
						left: 0;
						bottom: 0;
						height: 1px;
						width: 100%;
						background-color: #333;
						content: "";
					}
				}
				.marque{
					margin-top: calc(-1rem - 10px);
					display: flex;
					justify-content: flex-end;
					z-index: 9;			
					img{
						width: auto;
						height: 40px;
					}
				}
				.product-description-short {
					font-size: 16px;
					font-weight: normal;
					color: #333;
					line-height: 1.69;
					margin-top: 1.5rem;
				}
				.product-features{
					
					dl{
						padding: 0;
						margin: 0;
						position: relative;
						// padding: 0.25rem 0 0.25rem 1rem;
						&::before{
							width: 5px;
							height: 100%;
							background-color: $webapic_pink1;
							content: '';
							position: absolute;
							left: 0;
							top: 0;
						}
						dt span{
							font-weight: bold;
						}
						dt, dd{
							background:#d83381;
							color: #fff;
							padding: .75rem 0;
							flex: auto;
							// background: none; 
							font-size: 14px;
							font-weight: 300;
							line-height: 1.58;
							letter-spacing: 0.3px;
							margin: 0;
							padding: 0.5rem;
							// opacity: 0.8;
							min-height: auto;
						}
						dd::after{
							content: ' /';							
						}
						dd:last-child::after {
							display: none;
						}
					}
				}
				div.product-price-and-shipping > div{
					.price-ttc{
						font-size: 18px;
					}
					.price-reduction{
						font-size: 18px;
					}
					.price-ht{
						font-size: 32px;
						span{
							font-size: 16px;
						}
					}
				}
				.product-add-to-cart{
					position: relative;
					margin-top: 2.5rem;
					padding-top: 1rem;
					&::after{
						position: absolute;
						opacity: 0.2;
						left: 0;
						top: 0;
						height: 1px;
						width: 100%;
						background-color: #333;
						content: "";
					}
					.delivery{
						color: $webapic_pink1;
						font-size: 14px;
						line-height: 2;
						letter-spacing: 0.35px;
						margin-bottom: 1rem;
						background: transparent url('/img/webapic/picto-check.svg') no-repeat 0% 50%;
						padding-left: 2rem;
					}
					.product-quantity-touchspin .input-group{
						border-radius: 0;
						border: none;
						height: 70px;
						.input-group-btn > .btn{
							background: #fff;
							border-radius: 0;
							color: #333333;
						}
						input{
							border: none;
							font-size: 20px;
						}
					}
					.add{
						margin-right: 0;
						.add-to-cart {
							height: 70px;
							color: #000000;
							line-height: 28px;
							font-size: 18px;
							letter-spacing: 0.45px;
							text-transform: none;
							box-shadow: 0px 5px 13px 0 rgba(51, 51, 51, 0.24);
							background: linear-gradient(90deg, rgba(164,236,55,1) 0%, rgba(188,255,87,1) 100%);
						}
					}
					

				}
			}
			.wp-service{
				display: none;
				margin-top: 2rem;
				width: 100%;
				padding: 1rem 2rem 1rem 6rem;
				background: #fff url('/img/webapic/picto-service.svg') no-repeat 2rem 50%;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.35px;
				color: #333;
				text-align: center;
				span{
					display: block;
					font-size: 20px;
					letter-spacing: 0.5px;
					font-weight: bold;
					color: $webapic_pink1;
				}
			}
		}
	}
	.product-accessories{
		padding-top: 6rem;
		@extend .maxwidth;		
		.product-list{
			margin-top: 3rem;
		}
	}
	.ventesflash-compteur{
		position: absolute;
		top: 0;
		left: -25px;
		border-radius: 0;
		height: 300px;
		padding: 0;
		width: 60px;
		.px-1{
			display: none;
		}
	}
	
}

/*********catégorie PAGE*********/
#prices-drop,
#category{
	main{ 
		h1{
			margin: 0 0 2rem 0; 
			color: $webapic_pink1;
			font-weight: 600;
		}
		.main-content{
			margin-top: 2rem;
		}
		.wp-category{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.category-cover{
				width: 60%;	
				padding-right: 2rem;		
			}
			.category-description{ 
				width: 40%;		
			}
		}
		#js-product-list-top .product-display{
			display: none !important;
		}
		#left-column{
			.column-wrapper{
				position: sticky;
				top: 80px;
				#search_filters{
					max-height: 500px;
					overflow-y: scroll;
					overflow-x: hidden;
				}
			}
		}
	}
}

/*********block badge fixe*********/
.badgefixe{
	position: fixed; 
    right: -181px;
	z-index: 9999;
    color: #fff;
    top: 50%;
    background: #440099;
    transform: rotate(-90deg);
    font-size: 20px;
    text-transform: uppercase;
    padding: 0.5rem;
	span{
		color: #333;
		background-color: #fff;
		padding: 0 0.25rem;
	}
}

/*********panir banner*********/
.pscartbanner{
	text-align: center;
	border: 3px solid $webapic_pink1 !important;
	font-weight: bold;
	font-size: 1.05rem;
	strong{
		color: $webapic_pink1;
		font-size: 1.4rem;
	}
}

#cart{
	.cart-container{
		.delivery{
			color: $webapic_pink1;
			font-size: 12px;
			line-height: 2;
			letter-spacing: 0.25px;
			margin-top: 1rem;
			background: transparent url('/img/webapic/picto-check.svg') no-repeat 0% 50%;
			padding-left: 1.75rem;
		}
	}

}


.cart-detailed-actions{
	.payment-method-logo{
		margin: 2rem 0 0 0;
		img{ 
			width: 100%;
		}
	}
}

#cookieMessage{
	z-index: 9999999999999;
}


#blockcart-modal{
	.ets_crosssell_list_blocks{
		.featured-products{
			.slick-track{
				width: 100% !important;
			}
		}
	}
}

.left-ventesflash{
	.column-title a {
		color: #d83381;
		&:hover {
			color: #440099;
		}
	}
	article.product-miniature .product-container{
		flex-direction: column;
		padding: 0 !important;
		.first-block {
			max-width: initial;
			width: 100%;
			.product-flags {
				transform: scale(0.65);
			}
		}
		.miniature-countdown-wrapper {
			transform: scale(0.9);
			right: 10px;
		}
	}
}