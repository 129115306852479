#cart .cart-preview {
  .cart-dropdown {
    display: none !important;
  }
}

/** CART BODY **/
.cart-items {
  padding: 0 0.9375rem;
  .cart-item {
    padding: 10px 0;
    + .cart-item {
      border-top: 1px solid $border-color-lighten;
    }
  }
}
.product-line-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px;
  position: relative;
  .product-line-grid-left, .product-line-grid-right {
    width: 50%;
    display: flex;
    align-items: center;
  }
  .product-image {
    margin: 0 10px;
    max-width: 30%;
  }
  .product-line-grid-right {
    justify-content: space-between;
    > div {
      padding: 0 10px;
    } 
  }
  .qty-col {
    max-width: 140px;
  }
  .price-col {
    font-size: 125%;
  }
  .cart-line-product-actions {
    .remove-from-cart {
      font-size: 1rem;
    }
  }
  .product-name {
    padding: 0;
  }
  .product-prices {
    display: flex;
    align-items: center;
    margin-top: 3px;
    font-size: 0.875rem;
    span {
      font-size: inherit;
    }
  }
  .product-line-info-wrapper {
    margin-top: 5px;
  }
  .product-line-info {
    line-height: 18px;
    .material-icons {
      font-size: 15px;
    }
  }
  @include media-breakpoint-down(sm) {
    .product-line-grid-left, .product-line-grid-right {
      width: 100%;
    }
    .product-line-grid-left {
      margin-bottom: 10px;
    }
    .cart-line-product-actions {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.cart-items-review {
  .product-line-grid {
    flex-wrap: nowrap;
    .product-line-grid-left {
      width: 100%;
    }
    .product-line-grid-right {
      width: auto;
    }
    .product-prices .qty {
      color: $color_pink;
    }
  }
}

.cart-container {
}

.cart-overview .no-items {
  padding: $box-padding;
  border: none;
  border-radius: 5px;
}
.cart-continue-shopping {
  margin-top: 30px;
}



/** Cart and sidebar cart **/
.cart-summary-line {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  label, small {
    margin-bottom: 0;
  }
}
.cart-summary-subtotals {
  label {
    font-weight: 400;
  }
  .price {
    font-size: 0.9rem;
    font-weight: 400;
  }
}



/** CART RIGHT **/
.cart-summary {
  position: relative;
  overflow: hidden;
  .cart-detailed-actions {
    padding: 20px 0;
    margin: 0 (-$box-padding);
    background: $color_gray;
    text-align: center;
    .alert.alert-warning {
      padding: 8px 15px;
      margin-top: -20px;
    }
  }
  .cart-products-count {
    padding: 15px 0;
    text-align: center;
    margin: 0 (-$box-padding);
    font-weight: 700;
  }
  .cart-voucher {
    .promo-code {
      .input-group {
        padding: 5px 0;
      }
      .alert-danger {
        padding: 0.5rem;
        margin: 0;
        display: none;
        .material-icons {
          margin-top: -2px;
        }
      }
    }
    .promo-name {
      margin-bottom: 7px;
      .remove-voucher i {
        font-size: 1rem;
        width: 20px;
        text-align: center;
      }
    }
    .promo-highlighted {
      margin-top: 8px;
      margin-bottom: 3px;
    }
    .promo-discounts {
      margin-bottom: 0;
      padding: 0 3%;
      li {
        padding: 0;
      }
      .code {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}



