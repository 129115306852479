@import "_aonemegamenu";
@import "_aoneslideshow";
@import "_aonehomeblocks";
@import "_aonefeaturedcategories";
@import "_aonebrandlogo";
@import "_aonepopupnewsletter";
@import "_aonecolorsfonts";
@import "_aoneproductadditional";
@import "_aonethememanager";

.typoImageSlider {
  position: relative;
  margin: 0 auto 30px;
  .js-typoImageSlider {
    overflow: hidden;
    > ul {
      display: flex;
      flex-wrap: nowrap;
      list-style: none;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0;
        > li {
          @include make-col(1, 1);
          padding: 0;
          img {
            margin: 0 auto;
            display: block;
          }
        }
    }      
  }
  .scrollArrows {
    z-index: 10;
    display: none;
    transition: all 0.3s;
    &.scroll {
      display: block;
    }
    .left, .right {
      position: absolute;
      top: 50%;
      margin-top: -15px;
      font-size: 30px;
      background: $icon-color;
      color: $light-text-color;
      border-radius: 50%;
      cursor: pointer;
      user-select: none;
    }
    .left {
      left: -15px;
    }
    .right {
      right: -15px;
    }
    &.x-small {
      .left, .right {
        margin-top: -10px;
        font-size: 20px;
      }
      .left {
        left: -10px;
      }
      .right {
        right: -10px;
      }
    }
    &.x-large {
      .left, .right {
        margin-top: -20px;
        font-size: 40px;
      }
      .left {
        left: -20px;
      }
      .right {
        right: -20px;
      }
    }
  }
}

.typoModalBox {
  margin-bottom: 30px;
  .modalContent {
    display: none;
  }
}

.typoShowMoreContent {
  padding-bottom: 10px;
  margin-bottom: 30px;
  .js-typoShowMoreContent {
    position: relative;
  }
  .descFull {
    overflow: hidden;
  }
  .descSmall {
    position: relative;
    height: 200px;
    overflow: hidden;
    transition: all 0.5s;
  }
  .descToggle {
    position: absolute;
    bottom: -10px;
    a {
      padding: 5px 8px;
      background: #ffffff;
      border-radius: 5px;
    }
    .expand, .collapse {
      display: none;
    }
  }
  .descCollapsed {
    .descSmall:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 70px;
      background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, rgba(247, 247, 247, 0)), color-stop(40%, rgba(247, 247, 247, 0.8)), color-stop(90%, #f7f7f7));
      background-image: -webkit-linear-gradient(top, rgba(247, 247, 247, 0) 0, rgba(247, 247, 247, 0.8) 40%, #f7f7f7 90%);
      background-image: -moz-linear-gradient(top, rgba(247, 247, 247, 0) 0, rgba(247, 247, 247, 0.8) 40%, #f7f7f7 90%);
      background-image: -o-linear-gradient(top, rgba(247, 247, 247, 0) 0, rgba(247, 247, 247, 0.8) 40%, #f7f7f7 90%);
      background-image: linear-gradient(top, rgba(247, 247, 247, 0) 0, rgba(247, 247, 247, 0.8) 40%, #f7f7f7 90%);
    }
    .descToggle .expand {
      display: block;
    }
  }
  .descExpanded {
    .descToggle .collapse {
      display: block;
    }
  }
}

.typoLightboxGallery {
  margin-bottom: 30px;
  ul {
    list-style: none;
    padding-left: 0;
    padding-right: 0;
    margin-left: -5px;
    margin-right: -5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > li {
      padding: 5px;
    }
  }
}
