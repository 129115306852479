.blockreassurance-wrapper {
  background: #ffffff;
}
.block-reassurance-item {
  padding: 10px;
  display: flex;
  align-items: center;
  img {
    height: auto;
  }
  .item-icon, .item-text{
    margin: 0 5px;
  }
}
.blockreassurance-product {
  .block-reassurance-item {
    + .block-reassurance-item {
      border-top: 1px dashed $border-color;
    }
  }
}
#product .blockreassurance-product {
  background: $color_gray;
  border: none;
  padding: 0;
}
#cart .blockreassurance-product {
  margin-bottom: 30px;
  padding: 0;
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .block-reassurance-item {
      border-top: none;
      display: block;
      text-align: center;
      .item-icon {
        margin-bottom: 8px;
      }
    }
  }
}
.blockreassurance-outside {
  padding: 10px 0;
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .block-reassurance-item {
      display: block;
      text-align: center;
    }
  }
}