/* Roboto-Regular-cyrillic-ext */
@font-face {
	font-family: 'Poppins';
	font-display: swap;
	font-style: normal;
	font-weight: 300;
	src: local('Poppins-Light'), local('Poppins-Light'), url(../fonts/Poppins-Light.ttf) format('truetype');
}
@font-face {
	font-family: 'Poppins';
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	src: local('Poppins-Regular'), local('Poppins-Regular'), url(../fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'Poppins';
	font-display: swap;
	font-style: normal;
	font-weight: 600;
	src: local('Poppins-Bold'), local('Poppins-Bold'), url(../fonts/Poppins-Bold.ttf) format('truetype');
}

/* Font Awesome */
@import "~font-awesome/scss/font-awesome";

/* Material Icons */
@font-face {
	font-family: 'Material Icons';
	font-display: block;
	font-style: normal;
	font-weight: 400;
	src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
	src: local('Material Icons'),
		 local('MaterialIcons-Regular'),
		 url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
		 url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
		 url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
  }
  
  .material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: inherit;  /* Preferred icon size */
	display: inline-block;
	vertical-align: middle;
	width: 1em;
	height: 1em;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
  
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
  
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
  
	/* Support for IE. */
	font-feature-settings: 'liga';
  }
  
