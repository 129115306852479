.block-newsletter {
  margin-bottom: 30px;
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }

  .form-control {
    &:-webkit-autofill {
      background: transparent;
    }
  }
  .newsletter-message {

    font-size: 92%;
    .alert {
      padding: 2px 10px;
      font-size: inherit;
      margin-bottom: .625rem;
      border: none;
    }
    .conditons {
      padding: 2px 0;
    }
  }
}
#footer .footer-main .block-newsletter h4 {
  margin-bottom: 20px;
}
.footer-right .block-newsletter {
  .input-wrapper {
    display: flex;
  }
  .form-control {
    height: 50px;
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    background: $color-gray;
    border-radius: 5px 0 0 5px;
    border-right-width: 0;
    color: inherit;
    border-color: $input-border-color;
  }
  .input-btn {
    min-width: 30%;
    .btn {
      width: 100%;
      height: 50px;
      font-size: 17px;
      border-radius: 0 5px 5px 0;
      text-transform: none;
    }
  }
}
