.searchbar-wrapper {
  width: 100%;
}
.search-widget {
  width: 70%;
  margin: auto;
  &.ui-front {
    position: relative;
  }
  form {
    position: relative;
    input[type="text"] {
      height: 43px;
      padding-right: 53px;
      padding-left: 5%;
      margin-left: -1px;
      background: $header-background;
      color: inherit;
      border-color: $input-border-color;
    }
    [type="submit"] {
      border: none;
      text-align: center;
      padding: 0;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 43px;
      height: 43px;
      transition: all 0.3s;
      cursor: pointer;
      border-radius: 0 5px 5px 0;
      .material-icons, .fa {
        font-size: 21px;
        font-weight: 700;
      }
    }
  }

  .mobile-header-version & {
    width: 80%;
    form {
      [type="submit"] {
        background: transparent;
        color: inherit;
      }
    }
  }
}
#products .search-widget {
  margin: auto;
}
.sidebar-search {
  padding: 20px;
  border-top: 1px solid $border-color;
  .search-widget {
    width: 100%;
  }
}

.ui-autocomplete.ui-menu {
  max-width: 100%;
  .search-menu-item {
    list-style: none;
    .search-item {
      display: flex;
      align-items: center;
      border-radius: 0;
      .info {
        flex: 1;
        min-width: 0;
        .category, .separator {
          display: none;
        }
      }
      .cover {
        margin-right: 2%;
        width: 50px;
        img {
          border: 1px solid $border-color-lighten;
        }
      }
      .pprice {
        font-weight: 700;
      }
      .pref {
        font-style: italic;
        display: none;
      }
      .product {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }
  }
}

.disable-price-mode .ui-autocomplete.ui-menu .search-menu-item .search-item .pprice {
  display: none;
}
