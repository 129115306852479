.aone-homepage {
  .product-list {
    .list {
      &.columns-2 {
        .product-miniature {
          @include media-breakpoint-up(lg) {
            @include make-col(1, 2);
          }
          .layout-left-column .main-content &, .layout-right-column .main-content & {
            @include media-breakpoint-only(lg) {
              @include make-col(1, 1);
            }
          }
        }
      }
      .product-miniature {
        .second-third-block {
          flex-direction: column;
          justify-content: space-between;
        }
        .product-list-reviews {
          margin-top: -5px;
        }
        .product-description-short {
          max-height: ($font-size-root * $line-height-base * 2);
          overflow: hidden;
        }
        .product-name {
          padding-bottom: 8px;
        }
        .product-description-short, .variant-links, .buttons-sections {
          margin-bottom: 8px;
        }
      }
    }
  }
}
  