@include media-breakpoint-down(sm) {

	header{
		.mobile-header-version{
			.header-event-banner-wrapper{
				color: #fff;
				text-align: center;
			}
			.header-nav{
				display: none;
			}
			.main-header{
				padding: 0;
				.header-wrapper{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					.header-logo{
						// width: 33% !important;
						display: none; 
					}
					#sticky-wrapper{
						width: 100% !important;
						&.is-sticky .header-right{
							padding: 0 15px;
							.searchbar-wrapper{
								
									left: 0;
								
							}
						}
						.mobile-display-left{
							order: 2;
							margin-left: 1rem; 
						}
						.header-right{
							width: 100% !important;  
							left: 0;
							position: relative;
						}
						.sticky-search{
							display: flex;
							color: #fff;
							font-size: 2rem;

						}
						.logo-mobile{
							display: flex;
							width: 40%;
							padding-right: 1rem;
							img{
								width: 100%;
								height: auto;
							}
						}
						.display-top{
							order: 1;
							justify-content: flex-end !important;
							.searchbar-wrapper{
								display: none;
								width: 100vw;
								padding: 0.5rem;
								margin: 0;
								order: 1;
								position: absolute;
								top: 60px;
								left: -15px;
								&::before{
									position: absolute;
									left: 0;
									top: 0;
									width: 100vw;
									background: $webapic_pink1;
									content: '';
									height: 100%;
								}
							}
							.customer-signin-module{
								order: 2;
								position: inherit;
								padding: 0;
								margin: 0;
								width: auto;
							}
							.shopping-cart-module{
								order: 3;
								position: inherit;
								padding: 0;
								margin: 0;
								width: auto;
							}
						}
					}
				}
			}
		
		}

	}

	main{
		.aone-slideshow {
			min-height: auto;
		}
		.aone-slideshow .a-banner img {
			height: 100%;
			width: auto;
			position: absolute;
    		top: 0;
		}
		#wrapper .aone-slideshow .nivo-html-caption, 
		#wrapper .aone-slideshow .nivo-caption {
			position: inherit;
			padding: 2rem 0 0 0;
			z-index: 99;
			width: 100%;
			.caption-wrapper{
				justify-content: center;
				height: auto; 
				.caption-content div {
					font-size: 20px;
				}				
			}
			.slide-products-related {
				position: inherit;
				transform: none;  
				top: auto;
				right: auto;
				.product-container{
					padding: 0 !important;
					flex-direction: column;
					> div.left{
						padding: 0 !important;
						width: 100%;
						text-align: center;
						.product-name a {							
							font-size: 18px !important;
						}
						span{
							width: 100%;
							font-size: 26px !important;
						}
						a.link{
							padding: 10px;
						}
					}
					> div.right{
						padding: 0 0 2rem 0!important;
						width: 50%;
						margin-top: 1rem;
						a img{
							display: block;
						}
						img{
							width: 100% !important;
							height: auto !important;
							display: none; 
						}
					}
				}
			}
		}
		#wrapper .home-top {
			margin: 0;
			padding: 1rem;
			flex-wrap: wrap;
			h2{
				width: 100%;
				text-align: center;	
				margin-bottom: 0.5rem;			
			}
			img{				
				max-width: 20%;
				padding: 0 0.25rem;				
			}
		}
		#wrapper h1 {
			margin: 20px 0;
		}
		.block-reassurance{
			padding: 0;
			ul::after{
				display: none;
			}
			ul li:first-of-type{
				font-size: 15px;	
				padding: 20px 0;
				span{
					div{
						margin: 0 !important;
					}
				}
				img{
					top: 310px;
				}
			}
			li{
				width: 50%;
				margin-bottom: -40px;
				padding: 70px 5px;
			}
		}
		.aone-brands{
			.brand-list{
				.brand-base{
					flex: 0 0 20%;
				}
			}
		}
		.home-producttabs{
			.block{
				margin-bottom: 2rem;
			}
		}
		.product-list{
			.grid{
				max-width: 100%;
				.product-miniature{
					.second-block{
						height: 5rem;
					}
					.product-price-and-shipping {
						flex-direction: column;
						margin: 0;
						> div{
							width: 100%;
								text-align: center;
							span{
								font-size: 16px;
							
							}
						}
					}
				}
			}
		}   
		#wrapper .home-cdp-ref{
			margin-bottom: 6rem;
			&::after{
				display: none;
			}
			.home-cdp-ref-ctn {
				padding: 50px 10px;
				p,
				h2{
					max-width: 100%;
				}
				.partenaire{
					position: inherit;
					left: 0;
					top: 0;
					height: auto;
					width: 100%;
					background: #fff;
				}
			}
			
		} 
		.product-accessories {
			max-width: 100% !important;
			text-align:  center;
		} 
		.block_id_12{
			flex-direction: column;
			> div{
				width: 100% !important;
				text-align: center !important;
				padding-right: 0 !important;
				span{
					padding: 0 !important
				}
			}
		}
		.main-content .aone-homepage{
			div.product-list .grid{	
				article{

					.product-container{
						.first-block{
							.product-flag{
								background-size: 100%;
								font-size: 12px;	
								line-height: 30px;
								width: 60px;
								height: 55px;
							}
						}
						.second-third-block{
							margin-top: 0;
							.promo2{
								.product-flag.discount{
									padding: 0 8px;
									font-size: 15px;
								}
							}
						}
					}
				}
			}
		}
		.pscartbanner{
			display: none;
		}

	}

	footer{
		.footer-top .block-newsletter {
			flex-direction: column;
			p,
			form{
				text-align: center;
				width: 100%;
				padding: 0;
			}
		}
		.footer-main{
			padding-top: 7rem;
			.footer-left{
				text-align: center; 
			}
		}
	} 

	#product main {

		.row{
			margin: 0;
			.center-column{
				padding: 0;
			}
		}
		.wp-product{
			flex-direction: column;
			.main-product-bottom-bg{
				&::before{
					width: 100%;
				}
			}
			.wp-product-left{
				width: 100%;
				.product-left{
					.product-left-bg{
						&::before{
							display: none;
						}
					}
					.product-normal-layout{
						padding: 1rem;
					}
				}
				.main-product-bottom-bg{
					display: none;
				}
				.product-normal-layout{
					padding: 1rem !important;
				}
			}
			.wp-product-right{
				width: 100%;
				max-width: 100%;
				padding: 0 1rem;
				.product-right {
					padding-left: 0;
					position: inherit;
					top: 0;
					h1{
						font-size: 25px;
						text-align: center;
					}
					div.product-price-and-shipping > div .price-ht {
						font-size: 20px;
					}
				}
				.product-right-content{
					display: flex;
					flex-direction: column;
					h1{
						order: 1;
					}
					.product-information{
						order: 2;
					}
					.product-features-block{
						order: 3;
					}
					.product-description-short{
						order: 4;
					}
				}
			}
		}
		.ventesflash-compteur{
			right: 30px;
			left: auto;
		}
	}  

	#category{
		main{
			.wp-category{
				flex-wrap: wrap; 
				.category-cover{
					width: 100%;	
					padding-right: 0;
					img{
						width: 100%;
						height: auto;
					}		
				}
				.category-description{
					width: 100%;		
				}
			}
		}
	}
	#category div.product-list .grid{	
		article{
			.product-container{
				border-bottom: 1px solid rgba(51, 51, 51, 0.7);
				padding-bottom: 1.5rem;
				border-radius: 0;
			}
		}
	}
	div.product-list .grid{
	
		article{
			margin-bottom: 1.5rem;
			.product-container{
				border-bottom: 1px solid rgba(51, 51, 51, 0.7);
				padding-bottom: 1.5rem;
				border-radius: 0;
				.first-block{
					.product-flag{
						background-size: 90%;
						font-size: 10px;	
						padding-left: 0.2rem;
						line-height: 23px;
						width: 46px;
    					height: 43px;
					}
				}
				.second-third-block{
					margin-top: 0;
					.promo2{
						.product-flag.discount{
							padding: 0 4px;
							font-size: 8px;
						}
					}
				}
				.third-block{
					margin-top: 0 !important;
				}
				.miniature-countdown-wrapper{
					display: none !important;
				}
			}
		}
	}
	.badgefixe{
		display: none;
	}

}

.left-ventesflash{
	// padding: 0 !important;
	.product-list-margin{
		padding: 0!important;
		margin: 0 !important;	
		.grid{
			justify-content: space-between;
			article{
				flex: 0 0 49%;
    			max-width: 49%;
				.product-container{
					padding: 0 0 1.5rem 0!important;
				}
			}
			
		}
		
	}
}